.cont {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: space-between;


  .box-full {
    width: 100%;
  }

  .box-half {
    width: 50%;
  }

  .p-r-30 {
    padding-right: 30px;
  }

  .m-r-30 {
    margin-bottom: 20px;
  }
}

@media (max-width: 998px) {
  .cont {
    padding: 10px;

    .box-half {
      width: 100%;
    }

    .p-r-30 {
      padding-right: 0;
    }
  }
}
