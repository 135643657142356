@import "src/styles/colors";

.cont {
  width: 100%;
  padding: 30px 20px 30px 20px;
  border: 1px solid $border-color;
  border-radius: 4px;
  margin-bottom: 100px;

  h5 {
    font-size: 1.2rem;
  }

  p {
    margin-bottom: 0;
    color: #7b7b7b;
  }
}


@media (max-width: 998px) {
  .cont {
    padding: 10px;
    width: 100%;
    margin: 0 auto 100px auto;

    h5 {
      font-size: 1rem;
    }

    p {
      font-size: 0.9rem;
    }
  }
}
