@import "src/styles/colors";

.contTab {
  display: flex;
  height: 58px;

  .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
    color: $content-color;
    border: 1px solid transparent;

    &.activate {
      $border: 1px solid $border-color;
      border-top: $border;
      border-left: $border;
      border-right: $border;
      box-shadow: 5px 0 5px rgba(0, 0, 0, 0.02);
      color: white;
      font-weight: 900;
      background: $main-color;
    }
  }
}
