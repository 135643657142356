@import '../../../../styles/webSizing';
@import '../../../../styles/colors';

.cont {
  display: flex;
  padding-top: 170px;



  .box-first-node {
    width: 250px;
    background: $main-color;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.03);

    .btn {
      display: flex;
      color: white;
      cursor: pointer;

      img {
        margin: 15px;
        width: 30px;
        height: 30px;
      }

      div {
        flex-grow: 1;
        line-height: 60px;
        height: 60px;
        overflow: hidden;
      }

      svg {
        margin: 17px 10px 0;
        width: 25px;
        height: 25px;
      }
    }

    .btn:hover {
      color: #1cb130;
      background: white;
    }
  }

  .box-child-node {
    display: flex;
    width: calc(100% - 250px);

    .box {
      background: rgba(255, 255, 255, 0.95);
      width: 33.3%;
      min-height: 100%;
      border-right: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.03);

      .btn-child-node {
        padding: 5px 5px 5px 15px;
        cursor: pointer;
        color: #7b7b7b;

        &:hover {
          background: #c4dde7;
        }
      }
    }
  }
}
