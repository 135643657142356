@import "src/styles/colors";

.cont {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid $border-color;
  height: 70px;
  cursor: pointer;

  &:hover {
    background-color: $hover-color;

    .label {
      text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
    }
  }

  .img {
    width: 30%;

    img {
      width: 50px;
      height: 50px;
    }
  }

  .label {
    color: #6d6d6d;
    font-weight: 900;
    height: 100%;
    width: 70%;
    line-height: 50px;
    font-size: 1rem;
  }
}
