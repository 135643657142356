@import "../../../../styles/inputCreateCompany.scss";

.cont {
  width: 100%;
  display: flex;
  margin: 15px 0px 0px 0px;

  .label {
    color: #9c9c9c;
    width: 15%;
    
  }

  .input {
    width: 85%;

    input {
      width: 100%;
    }
  }
}

@media (max-width: 998px) {
  .cont {
    .box {
      width: 100%;
      padding: 5px;
    }
  }
}
