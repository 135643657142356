@import "src/styles/table";
@import "src/styles/grid";

.box-img {
  width: 30%;

  .img-logo {
    margin-bottom: 10px;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    img {
      width: 100%;
    }
  }
}

.box-info {
  width: calc(70% - 10px);

}

.table-fluid {
  .col-header {
    width: 20%;
  }

  .col-value {
    width: 30%;
    word-break: break-all;
  }

  .col-full-value {
    width: 80%;
  }
}

.btn-file {
  width: 100%;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.pointer {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

@media (max-width: 998px) {
  .box-img {
    width: 100%
  }

  .box-info {
    width: 100%;
  }

  .table-fluid {
    width: 100%;

    .col-header {
      width: 40%;
    }

    .col-value {
      width: 60%
    }

    .col-full-value {
      width: 60%;
    }
  }
}
