@import "src/styles/table";

.table-fluid {
  .col-label {
    width: 20%;
  }

  .col-value {
    width: 30%;
  }
}

@media (max-width: 998px) {
  .table-fluid {
    .col-label {
      width: 30%;
    }

    .col-value {
      width: 70%;
    }
  }
}
