@import "src/styles/table";
@import "src/styles/inputCreateCompany";
@import "/src/styles/colors";

.textarea {
  height: 545px !important;
  resize: none;
  border-radius: 0 !important;
  overflow-y: scroll;
}

.blue {
  color: $main-color;
}

