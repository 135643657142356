@import "src/styles/colors";

.cont {
  display: flex;

  .img {
    margin-right: 10px;

    img {
      width: 50px;
      height: 50px;
      border: 1px solid $border-color;
      border-radius: 5px;
      overflow: hidden;
    }
  }

  .info {
    .nm {
      font-weight: 900;
      color: #3b3b3b;
    }
  }
}
