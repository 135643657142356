@import "../../../../styles/colors";
@import "../../../../styles/webSizing";
$nav-height: 120px;

.top-nav {
  height: $nav-height;
  border-bottom: 1px solid $border-color;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #f8f9fa;
}

.logoA {
  display: flex;
  justify-content: center;
  width: 260px;
}

.logo {
  padding-top: 15px;
  width: 245px;
}

.box-finder {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .box-input {
    width: 500px;
    border-radius: 19px;
    border: 3px solid $main-color;
    padding: 0 8px 0 20px;
    display: flex;

    input {
      border: none;
      width: calc(100% - 25px);
      height: 30px;
      margin: 0;
    }

    .icon-search {
      margin-top: 3px;
      width: 25px;
      height: 25px;
    }
  }
}

.btn-lang {
  display: flex;
  align-items: center;
}

.menu {
  padding: 10px;
}

.dropdown {
  color: $title-color !important;
  background-color: #f8f9fa !important;
  border: none !important;

  &:focus {
    box-shadow: 0px 0px 2px 5px rgba(0,0,0,0.05) !important;
  }
}