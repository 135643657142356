@import "src/styles/colors";

.cont {
  padding: 10px;
}

.table {
  border-top: 1px solid $border-color;
  border-left: 1px solid rgba(0, 0, 0, 0);
  border-right: 1px solid rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.card {
  border: 1px solid $border-color;
  border-radius: 5px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}

.header {
  display: flex;

  .contents {
    width: calc(100% - 40px);
  }

  .btn {
    width: 40px;
  }
}


.cont-contents {
  margin-top: 10px;
}
