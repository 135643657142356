@import "src/styles/colors";
@import "src/styles/grid";


.cont {
  flex-wrap: wrap;

  .box-div {
    aspect-ratio: 3/2;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .box-img {
      cursor: pointer;
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid $border-color;
      height: 100%;
      width: 100%;


      img {
        aspect-ratio: 3/2;
        object-fit: cover;
        width: 100%;
      }
    }
  }
}


@media (max-width: 998px) {
  .cont {
    .box-div {
      width: 100%!important;
    }
  }
}
