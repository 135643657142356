@import "src/styles/table";

.table {
  .col-id {
    width: 10%;
  }

  .col-is-open {
    width: 10%;
  }

  .col-title {
    width: 60%;
  }

  .col-created-at {
    width: 20%;
  }
}
