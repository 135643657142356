@import "src/styles/colors";

.box-title {
  .company-nm {
    color: #c6c6c6;
    font-size: 0.9rem;
    margin-bottom: -10px;
  }

  .catchphrase {
    padding-top: 10px;
    color: #525252;
  }

}

.price {
  font-size: 1.4rem;
  font-weight: 900;
  color: $main-color;
  margin-top: 10px;
  padding: 20px 0 20px 0;
  //border-bottom: 1px solid $border-color;
  //border-top: 1px solid $border-color;
}

.table {
  font-size: 1rem;
  display: flex;
  align-items: center;
  border-top: 1px solid $border-color;
  padding: 15px 0 15px 0;

  div {
    &:nth-child(1) {
      width: 30%;
      color: #a7a7a7;
      font-size: 0.9rem;
    }

    &:nth-child(2) {
      width: 70%;
      font-size: 0.9rem;
      text-align: right;
      word-break: break-all;
    }

    &.select {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .fileList {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: #212529 !important;
      }

      .btnDownload {
        padding: 0 !important;
        margin-left: 10px;
      }
    }
  }
}

