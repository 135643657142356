@import "src/styles/colors";
@import "src/styles/grid";

.p-5 {
  padding: 5px;
}

.btn-company {
  padding: 10px;
  cursor: pointer;
  border: 1px solid $border-color;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  min-height: 100%;

  &:hover {
    border-color: #dbdbdb;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.03);
  }

  img {
    width: 120px;
    height: 120px;
    object-fit: contain;
    margin-right: 15px;
  }

  .info {
    display: flex;
    flex-direction: column;
    //width: calc(100% - #{$size});
    flex-grow: 1;


    .company-nm {
      font-size: 1.3rem;
      color: $main-color;
      //border-bottom: 2px solid $main-color;
      word-wrap: anywhere;
      width: 100%;
    }

    .nation {
      margin-bottom: 5px;

      .flag {
        font-size: 0.8rem;
        margin-right: 5px;
      }

      .nm {
        margin-bottom: 5px;
      }
    }

    .space {
      flex-grow: 1;
    }

    .catchphrase {
      height: 40px;
      overflow: hidden;
      font-size: 0.8rem;
      color: #7b7b7b;
      text-wrap: normal;
      text-overflow: ellipsis;
    }
  }
}


@media (max-width: 998px) {
  .btn-company {
    padding: 5px;

    img {
      display: none;
    }


    .info {
      .company-nm {
        font-size: 1rem;
      }

      .nation {
        .nm {
          font-size: 0.8rem;
        }
      }
    }
  }
}
