@import "src/styles/colors";

.form {
  width: 300px;
  padding: 20px;

  border: 1px solid $border-color;

  input {
    margin-bottom: 10px;
  }
}
