@import "src/styles/table";


.cont-consultant {
  padding: 0;

  .box-lang {
    width: 10%;
  }

  .box-consultant {
    width: 80%;

    .table-consultant {
      $col-count: 10;

      &:nth-child(1) {
        border: none;
      }


      &.border-left-right {
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
      }

      .col-nm {
        width: calc(100% * 2 / #{$col-count});
      }

      .col-position {
        width: calc(100% * 2 / #{$col-count});
      }

      .col-email {
        width: calc(100% * 4 / #{$col-count});
      }

      .col-delete {
        width: calc(100% * 2 / #{$col-count});
      }
    }
  }


  .box-add {
    width: 10%;
  }

  .line-through {
    text-decoration: line-through;
  }
}


@media (max-width: 998px) {
  .cont-consultant {
    .box-lang {
      width: 20%;
    }

    .box-nm {
      display: none;
    }

    .box-email {
      width: 80%;
    }

    .box-tel {
      display: none;
    }

    .box-position {
      display: none;
    }

    .box-delete {
      display: none;
    }
  }
}
