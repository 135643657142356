@import "src/styles/colors";

.cont {
  width: 100%;
  display: flex;

  .box {
    width: 33.33%;
    margin-bottom: 30px;
  }
}

.box-nodata {
  width: 90%;
  height: 245px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 65px auto;
  border: 1px solid $border-color;
  border-radius: 5px;
}

@media (max-width: 998px) {
  .cont {
    flex-wrap: wrap;

    .box {
      width: 100%
    }
  }
}
