@import "src/styles/colors";

.cont {
  display: flex;
  position: relative;
  padding-top: 12px;


  .bar {
    top: 56px;
    height: 3px;
    background: $main-color;
    position: absolute;
    z-index: 0;
  }
}

.contTooltip {

  margin-top: 15px;
  font-size: 0.8rem;
  color: $content-color;
  text-align: center;
  display: flex;
  justify-content: center;


  .tooltip {
    width: 85%;
    padding: 5px;
    border: 1px solid $main-color;
    border-radius: 5px;
    font-weight: 400;
  }

}

.contCircle {
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1;

  .icon {
    margin: 0 auto;
    height: 30px;

    svg {
      color: $main-color;
    }
  }

  .circle {
    $size: 30px;
    height: $size;
    width: $size;
    overflow: hidden;
    border: 2px solid $main-color;
    border-radius: #{$size / 2};
    background: white;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .innerCircle {
      $innerCircleSize: 20px;
      width: $innerCircleSize;
      height: $innerCircleSize;
      border-radius: #{$innerCircleSize / 2};
      border: 2px solid $main-color;
    }

    &.activate {
      background: $main-color;
    }
  }


  .label {
    margin: 0 auto;
    color: $content-color;
    font-size: 0.9rem;
    text-align: center;

    &.activate {
      color: $main-color;
    }
  }
}

@media(max-width: 998px) {

  .cont {
    .bar {
      top: 49px;
      height: 2px;
    }
  }

  .contTooltip {
    .tooltip {
      border: 1px solid $main-color;
      font-size: 0.6rem;
      font-weight: 500;
    }
  }
  .contCircle {

    .icon {
      svg {
        width: 20px;
      }
    }

    .circle {
      $size: 15px;
      height: $size;
      width: $size;
      border-radius: #{$size / 2};
      margin-bottom: 5px;
    }

    .label {
      font-size: 0.8rem;
    }
  }
}

