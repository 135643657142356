@import "src/styles/table";
@import "src/styles/colors";
//
//.table {
//  .col-nm {
//    width: 30%;
//
//    .email {
//      text-align: center;
//      color: $content-color;
//    }
//
//    .company-nm {
//      text-align: center;
//      color: $content-color;;
//      font-size: 0.9rem;
//    }
//
//    .created-at {
//      text-align: center;
//      color: $content-color;;
//      font-size: 0.8rem;
//    }
//  }
//
//  .col-func {
//    width: 10%;
//  }
//
//  .col-reply {
//    width: 70%;
//    display: flex;
//    flex-wrap: wrap;
//
//    .reply {
//      width: calc(100% * 6 / 7);
//      color: $content-color;
//    }
//
//    .func {
//      width: calc(100% * 1 / 7);
//      display: flex;
//      justify-content: center;
//      align-items: center;
//    }
//
//    .file-list {
//      width: 100%;
//      padding-top: 10px;
//      margin-top: 10px;
//
//
//      .file-item {
//        &:hover {
//          cursor: pointer;
//          text-decoration: underline;
//        }
//      }
//
//      .file-title {
//        font-weight: 900;
//        color: $sub-title-color;
//        margin-bottom: 5px;
//      }
//
//    }
//  }
//}

.tableReply {
  .colLabel {
    width: 30%;

    .email {
      overflow: hidden;
      word-break: break-all;
      text-align: center;
      font-weight: 800;
    }

    .companyNm {
      word-break: break-all;
      text-align: center;
      color: $content-color;;
      font-size: 0.9rem;
    }

    .createdAt {
      text-align: center;
      color: $content-color;;
      font-size: 0.8rem;
    }
  }

  .colValue {
    width: 60%;
    color: $content-color;

    textarea {
      height: 100px;
      resize: none;
    }

    .fileItem {
      color: $content-color;

      span:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .colFunc {
    text-align: center;
    width: 10%;
  }
}

.boxAttachFile {
  margin-top: 30px;
  margin-bottom: 20px;

  .fileTitle {
    font-weight: 900;
    color: $sub-title-color;
    margin-bottom: 5px;
  }

  .fileItem {
    color: $content-color;

    span:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

@media (max-width: 998px) {

  .boxAttachFile {
    margin: 0;
    padding: 0;
    .fileTitle {
      margin-bottom: 0;
    }
  }


  .tableReply {
    flex-wrap: wrap;

    div {
      padding: 5px;
    }

    .colLabel {
      width: 30%;

      .email {
        font-size: 0.7rem;
        margin: 0;
      }

      .companyNm {
        margin: 0;
        font-size: 0.7rem;
      }

      .createdAt {
        font-size: 0.7rem;
      }
    }

    .colValue {
      width: 70%;
      color: $content-color;

      textarea {
        resize: none;
        height: 100px;
        font-size: 0.8rem;
      }
    }

    .colFunc {
      width: 100%;
      text-align: right;
    }
  }
}


.colorGreen {
  color: $green-color;
}

.colorBlue {
  color: $main-color;
}
