@import "src/styles/colors";
@import "src/styles/grid";


.cont {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
  justify-content: flex-start;
}

.cont-slide {
  max-width: 1200px;
  margin: auto;
}

.cont-goods {
  min-height: 100%;
  width: 20%;
  padding: 10px;

  &.slide {
    width: 100%;
  }

  .box-img {
    aspect-ratio: 1/1;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.01);
    margin-bottom: 5px;
    object-fit: cover;

    &:hover {
      cursor: pointer;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
    }

    img {
      object-fit: cover;
      width: 100%;
    }

    &.red {
      border: 1px solid rgb(255, 185, 185);
    }
  }

  .box-info {

    display: flex;
    flex-direction: column;
    width: 100%;

    .nm {
      color: $main-color;
      text-overflow: ellipsis;
      font-size: 1rem;
      font-weight: 900;
      word-wrap: anywhere;

      //overflow: hidden;
      //display: -webkit-box;
      //-webkit-line-clamp: 2;
      //-webkit-box-orient: vertical;
    }


    .space {
      flex-grow: 1;
    }

    .catchphrase {
      color: #9b9b9b;
      margin-bottom: 5px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: 40px;
      font-size: 0.8rem;
    }

    .price {
      font-weight: 900;
      text-align: right;
      font-size: 1rem;
      color: #585858;
      padding-left: 10px;
    }
  }
}


@media (max-width: 998px) {
  .cont-goods {
    width: 50%;

    .box-info {
      .nm {
        font-size: 0.9rem;
        margin-bottom: 0;
      }

      .catchphrase {
        font-size: 0.7rem;
        height: 30px;
      }

      .price {
        font-size: 0.8rem;
      }
    }
  }

}
