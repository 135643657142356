@import "src/styles/colors";

.cont {
  width: 80vw;
  //min-height: 100%;
  background: #f3f3f3;

  .box-logo {
    padding: 20px 20px 0;

    .logo {
      height: 60px;
    }
  }

  .box-auth {
    display: flex;
    padding: 10px;

    .btn {
      width: calc(50% - 10px);

      &:nth-child(1) {
        margin-right: 15px;
      }
    }
  }

  .title {
    padding: 10px;
    font-size: 1rem;
    color: $main-color;
  }

  .box-accordion {
    padding: 10px;

    .box-find {
      padding: 10px;
      display: flex;
      justify-content: space-between;

      .input {
        width: 70%;
        font-size: 0.9rem;
      }

      .btn-find {
        width: 20%;
      }
    }

    .categ-nm {
      font-size: 0.9rem;
      color: #696969;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }


  .box-user {
    padding-left: 15px;
    color: #717171;
    p {
      margin-bottom: 0;
    }
  }


  .box-sub {
    width: 100%;
    padding: 10px 5px 10px 10px;
    font-size: 0.8rem;
    color: #9b9b9b;
  }


}
