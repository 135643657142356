@import "src/styles/colors";
@import "src/styles/inputCreateCompany";
@import "src/styles/table";

.desc {
  color: $info-color;
  font-size: 0.9rem;
}

.btn-add {
  color: #c3c3c3;
  cursor: pointer;

  &:hover {
    color: #7b7b7b;
  }
}

.table {
  .col-delete {
    width: 20%;
  }

  .col-nm {
    width: 80%;
  }
}
