@import "../../../../styles/webSizing";
@import "../../../../styles/colors";

$nav-height: 50px;

.alignItemsCenter {
  align-items: center;
}

.btm-nav {
  height: $nav-height;
  border-bottom: 1px solid $border-color;
  background: #ffffff;

  .box-category {
    width: 250px;
    height: $nav-height;
    display: flex;
    background: $main-color;
    cursor: pointer;

    svg {
      color: #ffffff;
      margin: 13px 10px 0 15px;
    }

    .content {
      flex-grow: 1;
      line-height: $nav-height;
      text-align: center;
      font-size: 1.2rem;
      color: #ffffff;
    }

  }

  .box-item {
    font-size: 1.1rem;
    padding: 0 25px 0 25px;
    cursor: pointer;
    color: #9c9c9c;
    $hover-color: #4b4b4b;

    &.sm {
      font-size: 0.8rem;
      color: #717171;
      padding: 0 0 0 15px;

      &:hover {
        background: none;
        color: $hover-color;
      }
    }

    &:hover {
      //background: #f3f3f3;
      color: $hover-color;
    }
  }


}

.userinfo {
  line-height: 18px;
  font-size: 0.8rem;
  font-weight: 900;
  color: #717171;
  padding-right: 15px;
}
