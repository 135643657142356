@import "src/styles/table";
@import "src/styles/colors";

.table-list {
  flex-wrap: wrap;
  .box-created-at {
    width: 10%;
  }

  .box-status {
    width: 20%;
  }

  .box-goods {
    width: 40%;
  }

  .box-fee {
    width: 10%;
  }

  .box-func {
    width: 20%;
  }

  .box-progress {
    width: 100%;
  }
}


@media (max-width: 998px) {
  .table-list {
    flex-wrap: wrap;
    justify-content: flex-end;

    .box-created-at {
      display: none;
    }

    .box-status {
      display: none;
    }

    .box-goods {
      width: 100%;
    }

    .box-fee {
      width: 20%;
    }

    .box-func {
      width: 30%;
    }

    .box-progress {
      width: 100%;
    }
  }

  .mb-none {
    display: none;
  }
}
