@import "src/styles/table";

.table {
  .col-id {
    width: 10%;
  }
  .col-status {
    width: 20%;
  }
  .col-company {
    width: 40%;
  }
  .col-func {
    width: 30%;
  }
}


@media (max-width: 998px) {
  .table {
    .col-id {
      display: none;
    }
    .col-status {
      display: none;
    }
    .col-company {
      width: 70%;
    }
    .col-func {
      width: 30%;
    }
  }
}
