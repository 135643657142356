@import "src/styles/table";
@import "src/styles/colors";

.disable-flex {
  flex: unset !important;
}

.accordion-summary {
  background: #fafafa;
}

.cont-summary {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;

  .box-alleducode {
    width: 30%;
    color: $content-color;
    text-align: center;
    font-size: 0.8rem;
  }

  .box-goods {
    width: 40%;
  }

  .box-info {
    display: flex;
    align-items: center;
    width: 12%;
    text-align: left;
    color: #9c9c9c;
    font-size: 0.8rem;

    svg {
      margin-right: 5px;

      &.red {
        color: #ff1414;
      }

      &.green {
        color: #1cb61c;
      }

      &.grey {
        color: #8c8c8c;
      }
    }
  }
}

.cont-detail {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .box-full {
    width: 100%;
  }
}


.table-consultant {
  .col-position {
    width: 20%;
  }

  .col-nm {
    width: 20%;
  }

  .col-email {
    width: 30%;
  }

  .col-tel {
    width: 20%;
  }

  .col-lang {
    width: 10%;
  }

  .line-through {
    text-decoration: line-through;
  }
}

.table-history {
  .col-goods {
    width: 40%;
  }

  .col-code {
    width: 30%;
  }

  .col-func {
    width: 30%;
  }
}

.table-certificate {
  .col-product-warranty {
    width: 40%;
  }

  .col-certification-request {
    width: 30%;
  }

  .col-certificate {
    width: 30%;
  }
}

.table-btn {
  width: 100%;

}

@media (max-width: 998px) {
  .cont-summary {
    justify-content: center;
    flex-wrap: wrap;

    .box-goods {
      width: 100%;
    }
    .box-alleducode {
      width: 100%;
      text-align: left;
      padding-left: 57px;
    }

    .box-info {
      width: 33%;
    }
  }

  .table {

    .header {
      display: none;
    }

    .box-id {
      display: none;
    }

    .box-display {
      display: none;
    }

    .box-goods {
      padding-left: 10px;

      div {
        padding: 0;
      }

      width: 100%;
    }

    .box-func {
      text-align: right !important;
      width: 100%;
    }
  }
}
