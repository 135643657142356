@import '../../../../styles/webSizing';
@import '../../../../styles/colors';

.cont {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;

  .logoBox {
    margin-right: 20px;
    width: 200px;
    .logo {
      width: auto;
    }
  }


  .box {
    padding-left: 20px;
    width: calc(100% - 200px);

    &:last-child {
      margin-right: 0;
    }

    .title {
      font-size: 1rem;
      font-weight: 800;
    }

    p {
      font-size: 0.8rem;
      color: #7b7b7b;
      margin-bottom: 5px;
    }

    .box-sitemap {
      display: flex;
      flex-wrap: wrap;

      .btn-sitemap {
        padding: 0 10px 5px 0;
        color: #000000;
        font-size: 0.8rem;
        font-weight: bold;
        cursor: pointer;

        &:hover {
          color: black;
        }
      }
    }
  }
}
