@import "../../../../../styles/colors";
@import "../../../../../styles/inputCreateCompany";
@import "../../../../../styles/table";

.cont-title {
  display: flex;

  .title {
    width: 50%
  }

  .checkbox {
    width: 50%;
    font-size: 0.8rem;
    text-align: right;
  }
}

.box {
  display: flex;
  justify-content: space-between;
  div {
    width: calc(50% - 5px);
  }
}

.m-0 {
  margin: 0;
}
