@import "src/styles/colors";

.cont {
  border: 1px solid $border-color;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.02);

  .chatRoomBtn {
    $logoSize: 60px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid $border-color;


    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;


    .logo {
      width: $logoSize;

      .dummyLogo {
        width: $logoSize;
        height: $logoSize;
        aspect-ratio: 1/1;
      }

      .unknownLogo {
        width: $logoSize;
        height: $logoSize;
        aspect-ratio: 1/1;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: $logoSize;
          height: $logoSize;
          color: #d9d9d9;
        }
      }
    }

    .info {
      width: calc(100% - #{$logoSize + 20px});

      .nm {
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .content {
        text-overflow: ellipsis;
        font-size: 0.9rem;
        overflow: hidden;
        color: $content-color;
        height: 25px;
      }

      .date {
        font-size: 0.8rem;
        color: $sub-title-color;
      }
    }
  }

  .chatRoomBtn:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}

