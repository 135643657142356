@import "src/styles/table";

.cont {
  width:  100%;
  display: flex;
  flex-wrap: wrap;

  .box {
    padding: 10px;
    width: 50%;
  }
}

@media (max-width: 998px) {
  .cont {
    .box {
      width: 100%;
      padding: 5px;
    }
  }
}
