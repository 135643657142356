@import "src/styles/colors";

.cont {
  border-top: 1px solid $border-color;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  height: 80px;
  padding: 5px 10px 5px 10px;
}

.contBtn {
  padding-top: 5px;
  display: flex;
  justify-content: space-between;

  button {
    width: calc(50% - 5px);
  }
}
