@import "../../../../styles/colors";
@import "../../../../styles/webSizing";

.box-login {
  width: 450px;
  max-width: 90vw;
  padding-top: 80px;
  margin: 0 auto 20px auto;

  .img-logo {
    display: block;
    width: 40%;
    margin: 0 auto;
  }

  h3 {
    text-align: center;
  }

  .logo {
    display: block;
    width: 300px;
    margin: 0 auto;
    cursor: pointer;
  }

  .box-form {
    margin-top: 30px;
    padding: 20px 30px 20px 30px;
    border-radius: 3px;
    box-shadow: 3px 3px 3px #f5f5f5;
    border: 1px solid #f5f5f5;

    p {
      margin-top: 20px;
      margin-bottom: 5px !important;
      font-size: 0.8rem;
    }
  }

  .btn {
    margin-bottom: 10px;
  }

  .box-sns {
    .col-6 {
      &.p-r-5 {
        padding: 0 5px 0 0 !important;
      }

      &.p-l-5 {
        padding: 0 0 0 5px !important;
      }
    }
  }
}


.img-social {
  width: 80%;
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
}
