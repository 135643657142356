@import "src/styles/table";

.table {
  .col-id {
    width: 10%;
  }
  .col-title {
    width: 50%;
  }

  .col-view {
    width: 10%;
  }

  .col-created-at {
    width: 15%;
  }

  .col-updated-at {
    width: 15%;
  }
}
