@import "src/styles/webSizing";
@import "src/styles/colors";

.cont {
  height: 60px;
  align-items: center;
  display: flex;
  width: 100%;

  &.bar {
    border-bottom: 2px solid $main-color;
  }

  &.sm-bar {
    border-bottom: 2px solid $sub-title-color;
  }

  .title {
    color: $main-color;
    font-size: 1.2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;


    svg {
      margin-top: -3px;
      margin-left: 5px;
    }
  }

  .space {
    flex-grow: 1;
  }

  .desc {
    color: #717171;
    font-size: 1rem;
  }
}

.title-sm {
  height: 70px;
  align-items: center;
  display: flex;
  font-size: 1rem;
  color: $sub-title-color;

  svg {
    font-size: 1.1rem;
    margin-top: -3px;
    margin-left: 5px;
  }
}

@media(max-width: 998px) {
  .cont {
    //padding-left: 10px;
    //padding-right: 10px;

    .title {
      padding-left: 5px;
      font-size: 1.1rem;
      flex-grow: 1;
    }

    .desc {
      font-size: 0.8rem;
    }
  }


  .title-sm {
    padding-left: 5px;
    font-size: 0.8rem;
  }
}
