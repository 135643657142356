.modal-header {
    width: 100%;
    height: 35px;
    background: #21D382;
}

.modal-close {
    cursor: pointer;
    float: right;
    vertical-align: middle;
    height: 100%;
    border: none;
}

.modal-title {
    float: left;
}

.modal-footer {
    display: inline-block;
    width: 100%;
    height: 35px;
    line-height: 35px;
    background: #21D382;
    justify-content: left;
    align-items: center; 
    font-size: 12px;
    padding-left: 3px;
    vertical-align: middle;
}

.modal-footer input {
    vertical-align: middle;
}