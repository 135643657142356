@import "src/styles/table";
@import "src/styles/colors";


.table {
  .col-id {
    width: 10%;
  }

  .col-status {
    width: 5%;
  }

  .col-title {
    width: 25%;

    span {
      font-size: 0.8rem;
    }
  }

  .col-goods {
    width: 20%;


    .alledu-code {
      font-size: 0.8rem;
      text-align: center;
      color: $content-color;
    }

    .goods-nm {
      text-align: center;
      color: $main-color;
      font-size: 0.8rem;
    }
  }

  .col-user {
    width: 20%;

    .email {
      font-size: 0.8rem;
      text-align: center;
      color: $content-color;
    }

    .company-nm {
      text-align: center;
      color: $main-color;
      font-size: 0.8rem;
    }
  }

  .col-created-at {
    width: 15%
  }

  .col-func {
    width: 5%;
  }
}


@media (max-width: 998px) {

  .table {
    .col-id {
      display: none;
    }

    .col-status {
      width: 20%;
    }

    .col-title {
      width: 45%;
    }

    .col-goods {
      display: none;
    }

    .col-user {
      display: none;
    }

    .col-created-at {
      width: 20%;
    }

    .col-func {
      width: 5%;
    }
  }

}
