@import "../../../styles/colors";

.contents {
  min-height: calc(100vh);
  border-bottom: 1px solid $border-color;
  padding-top: 170px;
}

.box-nav {
  position: fixed;
  height: 170px;
  width: 100%;
  margin: 0 auto;
  z-index: 100;
  background: #ffffff;
}

.box-categ {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99;
}
