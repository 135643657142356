@import "src/styles/table";

.table-fluid {
  .col-label {
    width: 20%;
  }

  .col-value {
    width: 30%;
  }
}


.table {
  .col-label {
    width: 30%;
  }

  .col-textarea {
    width: 60%;

    textarea {
      resize: none;
      overflow-y: scroll;
    }
  }

  .col-btn {
    width: 10%;
  }
}

@media (max-width: 998px) {
  .table-fluid {
    .col-label {
      width: 30%;
    }

    .col-value {
      width: 70%;
    }
  }
}
