@import "src/styles/table";

.table-fluid {
  .col-label {
    width: 20%;
  }
  .col-value {
    width: 30%;
  }
}

.contents {
  overflow: hidden;
}

.contents img {
  width: 100%;
  height: auto;
}
