.cont {
  background: #ffffff;
  border-radius: 5px;
  padding: 50px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);

  img {
    width: 100px;
    margin-bottom: 50px;
  }

  .desc {
    margin-top: 50px;
    border-top: 1px solid #bcbcbc;
    border-bottom: 1px solid #bcbcbc;
    padding: 20px 10px 20px 10px;
  }
}
