@import "src/styles/colors";

.contInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .boxInfo {
    padding: 10px;
    //width: calc(33% - 15px);
    display: flex;
    justify-content: space-between;

    .img {
      width: 60px;
      height: 60px;

      img {
        width: 100%;
        aspect-ratio: 1/1;
      }
    }

    .info {
      width: calc(100% - 75px);
      overflow: hidden;

      .title {
        font-weight: 700;
        color: $sub-title-color;
        margin-bottom: -5px;
      }

      .email {
        color: $content-color;
        margin-bottom: -5px;
      }

    }
  }
}

.contWriter {
  border-radius: 5px;
  border: 1px solid $border-color;
  height: 889px;
  background: rgba(0, 0, 0, 0.04);
  position: relative;
  overflow: hidden;

  .content {
    padding: 10px 20px 10px 10px;
    height: calc(100% - 50px);
    overflow-y: scroll;
  }

  .inputChat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    background: #e1e1e1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 0 10px 0 10px;

    input {
      width: calc(100% - 210px);
    }

    button {
      margin-left: 10px;
      width: 100px;
    }
  }
}

.contChatBubble {
  margin-top: 5px;
  display: flex;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  .defStyle {
    border: 1px solid $border-color;
    border-radius: 5px;
    overflow: hidden;
    max-width: 70%;
    padding: 10px;

    .chatWriter {
      font-size: 0.8rem;
    }

    .chatContent {
    }

    .createdAt {
      font-size: 0.8rem;
      margin-bottom: 10px;
      margin-left: 10px;
      color: #d0d0d0;
    }
  }

  .writer {
    text-align: right;
    background: white;
  }

  .party {
    text-align: left;
    background: #60b463;
    color: white;

    a {
      color: white;
    }
  }
}


.contDayDivider {
  height: 50px;
  display: flex;

  .line {
    width: calc(50% - 60px);
    height: 25px;
    border-bottom: 2px solid $border-color;
  }

  .day {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 120px;
    color: #888888;
  }
}

.contChatUserList {
  margin-bottom: 10px;
  .box {
    .email {
      padding-left: 10px;
      color: #6d6d6d;
    }

    .role {
      padding-left: 10px;
      color: $main-color
    }
  }
}

@media (max-width: 998px) {
  .contInfo {
    flex-wrap: wrap;

    .boxInfo {
      min-height: 82px;
      border: 1px solid $border-color;
      margin-bottom: 10px;
      border-radius: 3px;
    }
  }

  .contWriter {
    height: 450px;
  }
}
