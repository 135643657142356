@import "../../../../../styles/inputCreateCompany";
@import "../../../../../styles/colors";

.cont-breadcrumbs {
  padding: 10px;
}


.cont-scroll {
  overflow: scroll;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

  .cont {
    display: flex;


    .box {
      width: 33.33%;
      min-width: 200px;
      border-right: 1px solid $border-color;

      .item {
        $height: 45px;
        height: $height;
        line-height: $height;
        padding-left: 10px;
        padding-right: 10px;
        color: #717171;
        cursor: pointer;

        &:hover {
          background: #f1f1f1;
        }
      }

    }
  }

}
