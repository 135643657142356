@import "colors";

.table-fluid {
  width: 100%;
  display: flex;
  //align-items: center;
  flex-wrap: wrap;


  .col {
    border-top: 1px solid $border-color;
    text-align: center;
    padding: 20px 0 20px 0;
    color: $content-color;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

  }

  .col-header {
    color: black;
    font-weight: 700;
    text-align: center;
  }

  .col-full {
    flex-grow: 1;
  }
}


.table {
  display: flex;
  padding: 10px 0 10px 0;
  align-items: center;
  border-top: 1px solid $border-color;
  min-height: 70px;

  .header {
    color: black;
    font-weight: 700;
    text-align: center;
  }


  .col-header {
    color: black;
    font-weight: 700;
    text-align: center;
  }

  .col {
    color: $content-color;
    text-align: center;
    padding: 0 5px 0 5px;
  }

  .col-input {
    display: flex;
    align-items: center;
    padding: 0 5px 0 5px;
  }

  .col-full {
    width: 100%;
  }

  .col-half {
    width: 50%;
  }

  .col-flex-grow-1 {
    flex-grow: 1;
  }

  .inactivate {
    color: #c6c6c6;
  }

  .activate {
    color: #383838;
    font-weight: 800;
  }

  .mb-hidden {
    display: block;
  }

  .space {
    flex-grow: 1;
  }

  &.hover {
    &:hover {
      background: #f6f6f6;
    }
  }

  .pointer {
    cursor: pointer;
  }


  &.border-bottom {
    border-bottom: 1px solid $border-color;
  }

  &.margin-bottom {
    margin-bottom: 15px;
  }

  &.pointer {
    cursor: pointer;
  }


}

.table-mb {
  border-top: 1px solid $border-color;
  display: flex;
  flex-wrap: wrap;
  line-height: 40px;
  font-size: 0.8rem;
  justify-content: space-between;
  padding: 0 5px 0 5px;

  div {
    text-overflow: ellipsis;
    white-space: nowrap;
  }

}

@media (max-width: 998px) {
  .table {
    flex-wrap: wrap;
    padding: 5px;

    div {
      font-size: 0.8rem;
    }

    .mb-hidden {
      display: none;
    }
  }

  .table-fluid {
    .col {
      font-size: 0.8rem;
    }

    .col-header {
      font-size: 0.8rem;
    }
  }
}
