@import "../../../../styles/webSizing";
@import "../../../../styles/colors";


.cont {
  height: 30px;
  margin-bottom: 20px;

  .title-container {
    display: flex;
  }
  
  .title {
    color: $main-color;
    margin-bottom: 0;
    margin-right: 15px;
    font-size: 1.2rem;
    width: 50%;
  }

  .more {
    text-align: right;
    width: 50%;
  }

  .line {
    flex-grow: 1;
    height: calc(50% - 1px);
    border-bottom: 2px solid $main-color;
  }
}


@media (max-width: 998px) {
  .cont {
    height: 30px;
    margin-bottom: 0;

    .title {
      padding-left: 10px;
    }
  }
}
