@import "src/styles/table";


.table {
  .colLabel {
    width: 20%;
  }

  .colValue {
    width: 30%;
    word-break: break-all;
  }

  .colContact {
    display: flex;
    color: $content-color;

    .contact {
      text-align: center;
      width: calc(100% - 30px);
    }

    .btnEdit {
      text-align: center;
      width: 30px;
    }
  }
}

.tableReply {
  .colLabel {
    width: 30%;

    .email {
      overflow: hidden;
      word-break: break-all;
      text-align: center;
      font-weight: 800;
    }

    .companyNm {
      word-break: break-all;
      text-align: center;
      color: $content-color;;
      font-size: 0.9rem;
    }

    .createdAt {
      text-align: center;
      color: $content-color;;
      font-size: 0.8rem;
    }
  }

  .colValue {
    width: 60%;
    color: $content-color;

    textarea {
      height: 100px;
      resize: none;
    }


  }

  .colFunc {
    text-align: center;
    width: 10%;
  }


}

.boxFunc {
  padding-top: 10px;
  border-top: 1px solid $border-color;
  text-align: right;
}

.contents {
  border-top: 1px solid $border-color;
  padding-top: 20px;
  padding-bottom: 20px;
}

.boxAttachFile {
  margin-top: 20px;
  margin-bottom: 20px;

  .fileTitle {
    font-weight: 900;
    color: $sub-title-color;
    margin-bottom: 5px;
  }

  .fileItem {
    color: $content-color;

    span:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.colorGreen {
  color: $green-color;
}

.colorBlue {
  color: $main-color;
}


@media (max-width: 998px) {

  .tableReply {
    flex-wrap: wrap;

    div {
      padding: 5px;
    }

    .colLabel {
      width: 30%;
    }

    .colValue {
      width: 70%;
      color: $content-color;
    }

    .colFunc {
      width: 100%;
      text-align: right;
    }
  }
}
