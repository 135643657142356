@import "src/styles/table";
@import "src/styles/colors";

.box-goods {
  display: flex;
  width: 100%;

  .img {
    margin-right: 10px;

    img {
      height: 50px;
      width: 50px;
      border: 1px solid $border-color;
      border-radius: 5px;
      overflow: hidden;
    }
  }
  .goods-info {
    font-size: 0.7rem;
    width: calc(100% - 60px);
    overflow: hidden;

    .goods-nm {
      width: 100%;
      overflow: hidden;
      font-size: 0.8rem;
      font-weight: 800;
      color: $main-color;
      text-align: left;

    }
  }
}
