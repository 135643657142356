@import "src/styles/table";


.table {
  .col-all-edu-id {
    width: 30%;
  }

  .col-goods {
    width: 40%;
  }

  .col-lang {
    width: 10%;
  }

  .col-func {
    width: 20%
  }
}
