@import "src/styles/table";


.table {
  .col-add {
    width: 20%;
  }

  .col-position {
    width: 40%;
  }

  .col-nm {
    width: 40%;
  }
}
