@import "src/styles/colors";

.cont {
  height: 50px;
  line-height: 50px;

  .item {
    font-size: 0.8rem;
    font-weight: 900;
    cursor: pointer;

    &:hover {
      color: $main-color;
    }

  }
}

.root {
  min-height: 60px !important;
}

.li {
  font-size: 0.8rem;
  a:hover {
    color: $main-color;
  }
}

.ol {
  min-height: 60px !important;
  line-height: 60px;
}

.separator {

}

.item {
  color: #7b7b7b;

  &:active {
    //color: #7b7b7b;

  }
}
