.title {
  color: #5f5f5f;
  font-size: 1.1rem;
  font-weight: 800 !important;
  margin-bottom: 10px;
  padding-bottom: 5px;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.02);
}

.titleWithExplanation {
  color: #5f5f5f;
  font-size: 1.1rem;
  font-weight: 800 !important;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.02);
}

.explanation {
  color: #5f5f5f;
  font-size: 0.8rem;
  font-weight: 600 !important;
  margin-bottom: 10px;
  padding-bottom: 5px;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.02);
}