@import "src/styles/table";
@import "src/styles/grid";
@import "src/styles/colors";

.contents {
  width: 100%;

  img {
    width: 100% !important;
  }

  iframe {
    width: 100% !important;
    height: 500px;
  }
}


.table {
  .col-info-label {
    width: 40%;
  }

  .col-info-value {
    width: 60%;
    text-align: left;
  }
}
