@import "../../../../../styles/inputCreateCompany";
@import "src/styles/colors";

.desc {
  color: $info-color;
  font-size: 0.9rem;
}

.cont-img {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;

  .img {
    width: 150px;
    height: 150px;
    border: 1px solid #efefef;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 15px;
  }


  .add {
    width: 150px;
    height: 150px;
    font-size: 2rem;
    line-height: 150px;
    text-align: center;
    border: 1px solid #efefef;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 15px;

    svg {
      color: #cbcbcb;
      width: 50px;
      height: 50px;
    }

    &:hover {
      cursor: pointer;

      svg {
        color: #929292;
      }
    }
  }
}
