@import "src/styles/colors";

.cont {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    width: 70%;
    height: 60%;
    overflow: hidden;
    background: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);


    .header {
      display: flex;
      border-bottom: 1px solid $border-color;

      .space {
        flex-grow: 1;
      }
    }
  }
}


@media (max-width: 998px) {
  .cont {
    .box {
      width: 90%;
    }
  }
}
