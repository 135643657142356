@import "../../../../../styles/table";


.table {

  .box-check {
    width: 10%;
  }

  .box-label {
    width: 20%;
  }

  .box-input {
    width: 70%
  }
}
