@import "src/styles/colors";

.cont {
  display: flex;
  padding: 30px 20px 30px 20px;
  border: 1px solid $border-color;
  border-radius: 4px;
  margin-bottom: 90px;
  width: 100%;

  .box {
    display: flex;
    justify-content: center;
    width: 33%;

    .label {
      font-size: 1.2rem;
      text-align: center;
      margin-bottom: 0;
      color: #7b7b7b;
    }

    .value {
      font-size: 1.2rem;
      font-weight: 900;
      color: $main-color;
      margin-left: 15px;
    }
  }
}


@media (max-width: 998px) {
  .cont {
    margin-bottom: 20px;
    padding: 10px;
    flex-wrap: wrap;

    .box {
      width: 100%;
      margin-bottom: 10px;

      .label {
        font-size: 0.9rem;
        width: 50%;
        text-align: left;
      }

      .value {
        font-size: 0.9rem;
        width: 50%;
        text-align: right;
      }

    }
  }
}
