@import "../../../../../styles/table";


.table {


  .box-activate {
    width: 10%
  }

  .box-position {
    width: 15%;
  }

  .box-nm {
    width: 15%;
  }

  .box-email {
    width: 25%;
  }

  .box-tel {
    width: 15%;
  }

  .box-created-at {
    width: 10%
  }

  .box-edit {
    width: 20%;
  }

  .line-through {
    text-decoration: line-through;
  }
}

@media (max-width: 998px) {
  .table {
    .box-activate {
      width: 20%
    }

    .box-position {
      width: 20%;
    }

    .box-nm {
      display: none;
    }

    .box-email {
      width: 50%;
    }

    .box-tel {
      display: none;
    }

    .box-created-at {
      display: none;
    }

    .box-edit {
      width: 10%;
    }
  }
}
