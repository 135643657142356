@import "src/styles/table";


.table {
  .box-id {
    width: 10%;
  }

  .box-created-at {
    width: 20%;
  }

  .box-prev {
    width: 25%;
  }

  .box-next {
    width: 25%;
  }

  .box-func {
    width: 20%;
  }
}


@media (max-width: 1200px) {
  .table {
    .box-id {
      display: none;
    }

    .box-created-at {
      width: 20%;
    }

    .box-prev {
      display: none;
    }

    .box-next {
      width: 60%;
    }

    .box-func {
      width: 20%;
    }
  }
}
