@import "src/styles/colors";


.cont {
  padding: 10px;
  border-top: 1px solid $border-color;
  text-align: right;

  button {
    margin-left: 10px;
  }
}
