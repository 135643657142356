@import "src/styles/table";

.table {
  .col-label {
    width: 20%;
  }

  .col-value {
    width: 80%;
  }
}
