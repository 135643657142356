@import "src/styles/colors";

.cont {
  display: flex;
  justify-content: space-between;
  position: relative;

  .index {
    width: 13%;

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;

    img {
      aspect-ratio: 1/1;
      height: auto;
      object-fit: contain;

      width: 100%;
      border-radius: 5px;
      border: 1px solid $border-color;
      overflow: hidden;
      margin-bottom: 10px;
      display: block;
    }
  }

  .content {
    width: calc(87% - 20px);
    aspect-ratio: 1/1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid $border-color;

    img {

      width: 100%;
    }
  }
}
