@import "../../../../../styles/table";

.cont {
  padding: 10px;
}

.cmm-cont {
  padding: 10px;
  display: flex;

  .label {
    width: 20%;
  }

  .input {
    width: 80%;
  }
}

.red {
  color: #ff0000;
  padding-left: 2px;
}

.m-r-10 {
  margin-right: 10px;
}

@media (max-width: 998px) {
  .cont {
    padding: 5px;
  }
}
