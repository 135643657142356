@import "src/styles/colors";

.cont-tab {
  $height: 63px;
  height: $height;
  width: 100%;
  display: flex;
  border-bottom: 1px solid $border-color;
  margin-bottom: 20px;

  .btn-tab {
    position: relative;
    height: 100%;
    line-height: $height;
    text-align: center;
    flex-grow: 1;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 700;
    color: #7b7b7b;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .bar {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 2px;
  }
}

@media (max-width: 998px) {
  .cont-tab {
    $height: 50px;
    height: $height;
    font-size: 1rem;

    .btn-tab {
      line-height: $height;
    }
  }
}
