@import "src/styles/colors";

.table {
  display: flex;
  border-top: 1px solid $border-color;
  min-height: 60px;
  line-height: 60px;
  padding: 0 10px 0 10px;

  .label {
    width: 150px;
  }

  .value {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 150px);

    font-size: 0.8rem;
    div {
      cursor: pointer;
      color: #9b9b9b;
      margin-right: 20px;

      &:hover {
        color: $main-color;
      }
    }
  }
}


@media (max-width: 998px) {
  .table {
    .label {
      width: 100px;
      font-size: 0.9rem;
    }
  }
}
