@import "src/styles/table";


.textarea {
  height: 545px !important;
  resize: none;
  overflow-y: scroll;
}



