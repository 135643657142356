@import "src/styles/table";
@import "src/styles/colors";

.cont {
  width: 100%;
}

.table {
  .box-position {
    width: 20%;
  }

  .box-nm {
    width: 20%;
  }

  .box-email {
    width: 20%;
  }

  .box-tel {
    width: 20%;
  }

  .box-func {
    width: 20%;
  }
}

.btn-color {
  color: $content-color !important;
}

.btn-root {
  width: 100%;
  border: 1px solid $main-color !important;
}

.box-info {
  padding-top: 5px;
  border-top: 1px solid $border-color;
  text-align: right;
  color: $content-color;
}


