@import "../../../../../styles/inputCreateCompany";

.cont {
  width: 100%;
  display: flex;
  height: 31px;
  line-height: 31px;
  margin-bottom: 15px;

  .label {
    color: #9c9c9c;
    width: 100px;
  }

  .date-picker {
    width: 100%;
  }
}
