@import "src/styles/table";
@import "src/styles/inputCreateCompany";


.btn-add {
  color: #c3c3c3;
  cursor: pointer;

  &:hover {
    color: #7b7b7b;
  }
}


.info {
  color: #9c9c9c;
  font-size: 0.9rem;
}

.table {
  .col-delete {
    width: 20%;
  }

  .col-nation {
    width: 40%;
    text-align: center;
  }

  .col-sales {
    width: 40%;
  }

  .col-add {
    width: 80%;
  }
}
