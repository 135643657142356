@import "../../../styles/table";


.table-fluid {
  .col-label {
    width: 20%;
  }

  .col-value {
    width: 30%;
  }
}

.red {
  color: red;
}
