@import "src/styles/table";


.table {
  .col-label {
    width: 20%;
  }

  .col-value {
    width: 30%;

    .alledu-code {
      font-size: 0.8rem;
      text-align: center;
      color: $content-color;
    }

    .goods-nm {
      text-align: center;
      color: $main-color;
      font-size: 0.8rem;

      svg {
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .email {
      word-break: break-all;
      font-size: 0.8rem;
      text-align: center;
      color: $content-color;
    }

    .company-nm {
      word-break: break-all;
      text-align: center;
      color: $main-color;
      font-size: 0.8rem;
    }
  }
}

.boxAttachFile {
  margin-top: 60px;
  margin-bottom: 20px;

  .fileTitle {
    font-weight: 900;
    color: $sub-title-color;
    margin-bottom: 5px;
  }

  .fileItem {
    color: $content-color;

    span:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.boxFunc {
  padding-top: 10px;
  border-top: 1px solid $border-color;
  text-align: right;
}



@media (max-width: 998px) {
  .boxAttachFile {
    margin: 0;
    padding: 5px;
    font-size: 0.8rem;

    .fileTitle {
      margin-bottom: 0;
    }
  }

}

