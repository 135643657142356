.cont {
  $halfWidth: 50%;
  $margin: 40px;
  $boxSmWidth: calc(#{$halfWidth} - #{$margin/2});

  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;


  .box {
    width: 100%;
    margin-bottom: 40px;

    &.sm {
      width: $boxSmWidth;
    }

    &.lg {

    }
  }
}


@media (max-width: 998px) {
  .cont {
    padding: 10px;

    .box.sm {
      width: 100%;
    }
  }
}
