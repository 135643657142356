@import "src/styles/table";


.table-fluid {
  .col-header {
    width: 20%;
  }

  .col-value {
    width: 30%;
  }

  .col-full-value {
    width: 80%;
  }
}

.link {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

@media (max-width: 998px) {
  .table-fluid {
    .col-header {
      width: 40%;
    }

    .col-value {
      width: 60%;
    }

    .col-full-value {
      width: 60%;
    }
  }
}
