@import "../../../../../styles/colors";
@import "../../../../../styles/inputCreateCompany";

.cont {
  display: flex;

  .img {
    width: 120px;
    height: 120px;
    margin-right: 15px;
    border-radius: 5px;
    border: 1px solid $border-color;
    overflow: hidden;

    img {
      width: 120px;
      height: 120px;
    }
  }

  .input {
    flex-grow: 1;

    .input-img {
      display: flex;
      margin-bottom: 15px;

      div {
        margin-right: 15px;
      }

      button {
        width: 100px;
      }

    }

    p {
      color: #7b7b7b;
    }
  }
}
