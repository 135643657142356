@import "src/styles/colors";
@import "src/styles/table";
@import "src/styles/icons";

.titleColor {
  color: $sub-title-color;
}

.btnAdd {
  color: $content-color;
}

.infoContents {
  color: $content-color;
  font-size: 0.8rem;
}



.contFile {
  .boxFile {
    border: 1px solid $border-color;
    border-radius: 3px;
    padding: 5px;
    margin-bottom: 10px;

    .rowFile {
      padding: 5px;
      display: flex;
      align-items: center;

      svg {
        font-size: 1rem;
        margin-top: 3px;
        color: red;
        filter: grayscale(50);

        &:hover {
          filter: grayscale(0);
          cursor: pointer;
        }
      }

      .space {
        flex-grow: 1;
      }

      .attachFile {
        color: $content-color;
        font-size: 0.9rem;
        padding-left: 10px;
        padding-top: 2px;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}

.fileExtensionImg {
  width: 25px;
  margin-right: 10px;
}
