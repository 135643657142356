@import "src/styles/colors";

.space {
  flex-grow: 1;
}

.nav {
  background: #f8f9fa !important;

  .box-logo {
    img {
      height: 40px;
      margin-top: 5px;
    }
  }

}

.btm-nav {
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
  border-top: 1px solid #efefef;
}

.content {
  min-height: calc(100vh - 56px);
  padding-top: 56px;
  padding-bottom: 80px;
}

.footer {
  border-top: 1px solid $border-color;
  padding: 15px 15px 80px 15px;
  font-size: 0.8rem;
  color: #8a8a8a;
}
