@import "src/styles/webSizing";


.contChat {
  width: 100%;
  max-width: 1200px;
  padding-top: 20px;
  margin: auto;
  display: flex;
  justify-content: space-between;

  .boxList {
    width: 250px;
  }

  .boxChat {
    width: calc(100% - 270px);
  }

}


.contNoChatRoom {
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}
