@import "../../../../styles/webSizing";
@import "../../../../styles/colors";

.cont {
  border: 1px solid $border-color;
  display: flex;
  margin-bottom: 50px;
  position: relative;

  .box-carousel {
    width: 100%;
    border-left: 1px solid $border-color;
    //display: flex;
    //align-items: center;

    .img-cont {
      width: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
