@import "src/styles/table";


.tableAskBoard {
  .colId {
    width: 10%;
  }

  .colStatus {
    width: 10%;
  }

  .colTitle {
    width: 30%;
  }

  .colGoods {
    width: 20%;
    text-align: center;
    font-size: 0.8rem;


    .alleduCode {
      text-align: center;
      color: $content-color;
    }

    .goodsNm {
      text-align: center;
      color: $main-color;
      font-size: 0.9rem;
    }
  }

  .colCompanyNm {
    width: 20%;
  }

  .colCreatedAt {
    width: 10%
  }
}


@media (max-width: 998px) {
  .tableAskBoard {
    .colId {
      display: none;
    }

    .colStatus {
      width: 10%;
    }

    .colTitle {
      width: 70%;
    }

    .colGoods {
      display: none;
    }

    .colCompanyNm {
      display: none;
    }

    .colCreatedAt {
      width: 20%;
    }
  }
}
