@import '~bootstrap/dist/css/bootstrap.min.css';
@import "~flag-icon-css/css/flag-icon.min.css";
@import "colors";

* {
  //font-family: "noto-sans-cjk-kr", sans-serif !important;
}

// 인풋 박스 포커스때 테두리 삭제
input:focus {
  outline: none;
}

*:focus {
  outline: none;
}

button:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: $content-color;
  &:hover {
    color: unset;
    text-decoration: underline;
  }
}

.web-view {
  display: block;
}

.mobile-view {
  display: none;
}

.ellipsis {
  overflow: hidden;
  text-wrap: normal;
  text-overflow: ellipsis;
  white-space: nowrap;;
}


@media (max-width: 998px) {
  .web-view {
    display: none;
  }
  .mobile-view {
    display: block;
  }
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
