@import "src/styles/colors";

.fileNm {
  color: $content-color;

  svg {
    margin-right: 5px;
  }
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
