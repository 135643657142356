@import "../../../../../styles/inputCreateCompany";

.cont {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  padding: 0 !important;

  .label {
    color: #9c9c9c;
    width: 20%;
  }

  .input {
    width: 80%;

    input {
      width: 100%;
    }
  }

  .red {
    color: red;
  }
}

