@import "src/styles/colors";

.box-tab {
  $b-color: #e0e0e0;
  $height: 50px;
  display: flex;
  border-top: 2px solid $b-color;
  border-left: 1px solid $b-color;


  .tab {
    width: 33.3333%;
    color: #4e4e4e;
    text-align: center;
    font-size: 1rem;
    font-weight: 900;
    text-decoration: none;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.open {
      border-right: 1px solid $b-color;
      background-color: $main-color;
      color: white;
    }

    &.hide {
      background: #f8f8f8;
      border-bottom: 1px solid $b-color;
      border-right: 1px solid $b-color;
    }
  }
}

@media (max-width: 998px) {

  .box-tab {

    .tab {
      padding: 5px;
      font-size: 0.8rem;
    }
  }
}
